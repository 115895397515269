import React from 'react';

const Footer = () => {
   return (
      <footer>
         <p>© 2023 Harry Ye. All rights reserved.</p>
      </footer>
   );
};

export default Footer;
