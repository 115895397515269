import React from 'react';

const Header = () => {
   return (
      <header>
         <h1>Displays 10 images at random</h1>
      </header>
   );
};

export default Header;
