const shoots = [
    {
      id: 1,
      folder: 'eric21st',
      title: 'Erics 21st',
      thumbnail: '/shoots/eric21st/eric21-163.jpg',
      images: Array.from({ length: 268 }, (_, index) => `/shoots/eric21st/image${index + 1}.jpg`),
    },
    {
      id: 2,
      folder: 'jazlily21st',
      title: 'Jaz and Lilys 21st',
      thumbnail: '/shoots/jazlily21st/5DM40467.jpg',
      images: Array.from({ length: 497 }, (_, index) => `/shoots/jazlily21st/image${index + 1}.jpg`),
    },
    // Add more shoots as needed
  ];
  
  export default shoots;
  