import React, { useState, useEffect } from 'react';
import shootsData from '../shootsData';
import Modal from 'react-modal';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import imageList from '../imageList.json';

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedShoot, setSelectedShoot] = useState(null);
  const [imageNames, setImageNames] = useState([]);
  const [showFolders, setShowFolders] = useState(true);

  // Function to handle opening a folder and hiding folder view
  const openModal = (shoot) => {
    setSelectedShoot(shoot);
    setShowFolders(false);
  };

  // Function to handle closing the gallery and showing folders again
  const closeModal = () => {
    setModalIsOpen(false);
    setShowFolders(true);
  };

  // Update image names when a shoot folder is selected
  useEffect(() => {
    if (selectedShoot) {
      setImageNames(imageList[selectedShoot.folder]);
    }
  }, [selectedShoot]);

  // Function to get 10 random image names without duplicates
  const getRandomImageNames = () => {
    const randomImageNames = [];
    const tempImageNames = [...imageNames];
    while (randomImageNames.length < 10 && tempImageNames.length > 0) {
      const randomIndex = Math.floor(Math.random() * tempImageNames.length);
      randomImageNames.push(tempImageNames[randomIndex]);
      tempImageNames.splice(randomIndex, 1);
    }
    return randomImageNames;
  };

  // Map the random image names to the gallery image format
  const galleryImages = getRandomImageNames().map((imageName) => {
    return {
      original: `${process.env.PUBLIC_URL}/shoots/${selectedShoot.folder}/${imageName}`,
      thumbnail: `${process.env.PUBLIC_URL}/shoots/${selectedShoot.folder}/${imageName}`,
    };
  });

  return (
    <div className="gallery">
      {/* Render folder thumbnails */}
      {showFolders &&
        shootsData.map((shoot) => (
          <div key={shoot.id} onClick={() => openModal(shoot)}>
            <img src={shoot.thumbnail} alt={shoot.title} />
            <h2>{shoot.title}</h2>
          </div>
        ))}
      {/* Render the thumbnail gallery when a folder is selected */}
      {selectedShoot && !modalIsOpen && (
        <div>
          <div className="thumbnail-gallery">
            <ImageGallery items={galleryImages} disableLightbox />
          </div>
        </div>
      )}
      {/* Render the modal with full-size image gallery */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        {selectedShoot && (
          <div>
            <ImageGallery items={galleryImages} />
          </div>
        )}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default Gallery;
